import * as R from 'ramda'
import * as React from 'react'

import PropTypes from 'prop-types'

export function PwclickObserver(props) {
  React.useEffect(() => {
    const query = R.pathOr({}, ['query'], props)
    localStorage.setItem('pwclickMessage', JSON.stringify(query))

    // If the processing-step was opened in a new browser/tab close the window
    if (window.top === window.self) {
      window.close()
    }
  })

  return null
}

PwclickObserver.propTypes = {
  query: PropTypes.object,
}
